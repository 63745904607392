<template>
    <div class="reduce">
      <div class="df-row">
        <!-- 日期时间选择器 -->
        <div class="df-col">
          <el-date-picker
            v-model="multipleDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
            value-format="yyyy-MM-dd"
            @change="onChangeDate"
          >
          </el-date-picker>
        </div>
  
        <div class="df-col">
          <!-- 搜索框 -->
          <el-input placeholder="请输入" v-model="submitData.word" size="mini">
          </el-input>
        </div>
  
        <div class="df-col">
          <!-- 查询按钮 -->
          <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
        </div>
      </div>
  
      <!-- start 表格 -->
      <el-table
        v-loading="loading"
        :data="tableList"
        :max-height="tableMaxHeight"
        size="mini"
        border
        stripe
      >
        <el-table-column prop="BillNo" label="单号" min-width="100">
        </el-table-column>
        <el-table-column prop="MemberName" label="会员姓名" min-width="90">
        </el-table-column>
        <el-table-column prop="MemberPhone" label="会员手机" min-width="110">
        </el-table-column>
        <el-table-column prop="PackCardName" label="套卡类型" min-width="120">
        </el-table-column>
        <el-table-column prop="PackVipNo" label="套卡卡号" min-width="100">
        </el-table-column>
        <el-table-column prop="ItemNo" label="项目编号" min-width="80">
        </el-table-column>
        <el-table-column prop="ItemName" label="项目名称" min-width="130">
        </el-table-column>
        <el-table-column prop="UnitsName" label="项目单位"> </el-table-column>
        <el-table-column prop="Consume" label="剩余次数"> </el-table-column>
        <el-table-column prop="ReduceCount" label="删除次数"> </el-table-column>
        
        <el-table-column label="删除日期" min-width="100">
          <template slot-scope="scope">
            {{
              scope.row.ReduceTime
                ? util.dateFormat(
                    "YYYY-mm-dd HH:MM:SS",
                    new Date(scope.row.ReduceTime * 1000)
                  )
                : ""
            }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="记账日期" min-width="100">
          <template slot-scope="scope">
            {{
              scope.row.SoftwareDate
                ? util.dateFormat(
                    "YYYY-mm-dd",
                    new Date(scope.row.SoftwareDate * 1000)
                  )
                : ""
            }}
          </template>
        </el-table-column> -->

        <el-table-column prop="ReduceAdminName" label="操作者"> </el-table-column>
        <el-table-column label="操作" fixed="right" min-width="100">
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              :disable="cancelDisabled"
              @click="reverseVipitemReduce(scope.row)"
              >撤销</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- end 表格 -->
  
      <!-- start 分页器 -->
      <el-pagination
        background
        :page-sizes="[10, 15, 20, 25]"
        :page-size="submitData.page_size"
        :current-page="submitData.page_index"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRecord"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <!--end 分页器-->
    </div>
  </template>
  
  <script>
  import Vip from "@/api/vip.js";
  
  export default {
    data() {
      return {
        tableMaxHeight: "",
        totalRecord: 0,
        submitData: {
          word: "",
          begin_date: "",
          end_date: "",
          page_index: 1,
          page_size: 15,
        },
        multipleDate: [],
        tableList: [],
        cancelDisabled: false,
        loading: false,
      };
    },
  
    created() {
      this.getResize();
    },
  
    mounted() {
      window.addEventListener("resize", this.getResize);
      this.multipleDate = [
        this.util.getNowMonthToday(),
        this.util.getNowMonthToday(),
      ];
      this.onChangeDate();
      // this.getVipitemReduceMaintList()
    },
  
    destroyed() {
      window.removeEventListener("resize", this.getResize);
    },
  
    methods: {
      // 获取浏览器窗口大小
      getResize() {
        let { clientHeight } = this.util.getDocumentElementInfo();
        this.tableMaxHeight = clientHeight - (100 + 68 + 100);
      },
  
      // 查询次数转金额
      async getVipitemReduceMaintList() {
        try {
          let { data } = await Vip.getVipitemReduceMaintList(this.submitData);
          this.totalRecord = data.filter.TotalRecord;
          this.tableList = data.list;
        } catch (err) {
          console.log(err);
        }
      },
  
      // 撤销次数转金额
      async reverseVipitemReduce(event) {
        this.$confirm("确定要撤销吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          this.cancelDisabled = true;
          try {
            let { errcode } = await Vip.reverseVipitemReduce({
              bill_guid: event.BillGuid,
            });
            if (errcode == 0) {
              this.$message.success('撤销成功');
              this.getVipitemReduceMaintList();
            }
          } catch (err) {
            console.log(err);
          } finally{
            this.cancelDisabled = false;
          }
        });
      },
  
      onSearch() {
        this.submitData.page_index = 1;
        this.getVipitemReduceMaintList();
      },
  
      onChangeDate() {
        this.submitData.begin_date =
          this.multipleDate.length > 0
            ? this.multipleDate[0]
            : this.util.getNowMonthToday();
        this.submitData.end_date =
          this.multipleDate.length > 0
            ? this.multipleDate[1]
            : this.util.getNowMonthToday();
        this.submitData.page_index = 1;
      },
  
      // 分页：每页显示多少条数据
      handleSizeChange(event) {
        this.submitData.page_index = 1;
        this.submitData.page_size = event;
        this.getVipitemReduceMaintList();
      },
  
      // 分页：跳转到第几页
      handleCurrentChange(event) {
        this.submitData.page_index = event;
        this.getVipitemReduceMaintList();
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .reduce {
    .el-pagination {
      margin-top: 20px;
      float: right;
    }
  }
  </style>
  